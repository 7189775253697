<!--
 * @Description: 商品菜单
 * @Autor: zhangzhang
 * @Date: 2021-06-10 16:00:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-02-04 15:14:53
-->
<template>
  <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar'
export default {
  components: {
    SideBar
  },

  data() {
    return {
      groups: [
        {
          label: '控制台',
          list: [
            {
              name: '首页数据',
              path: '/admin/home/data'
            }
          ]
        },
        {
          label: '审核',
          list: [
            {
              name: '域名绑定',
              path: '/admin/home/domain'
            }
          ]
        }
      ]
    }
  }
}
</script>
